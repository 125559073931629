<template>


    <!-- BEGIN: Content-->
    <div class="content-body">
        <!-- full job description -->
        <!-- Bordered table start -->
        <div class="row" >
            <div class="col-12">
                <div v-if="isTeamRequest">
                    <base-card-plain :showFooter="false" >
                        <template #header>

                            <div class="col-12 col-md-12 col-lg-12 col-sm-12 mb-1">
                                <div class="input-group">
                                    <input type="text" class="form-control"
                                           placeholder="Search using team mebr's name "
                                           aria-describedby="button-addon2"
                                           v-model="teamSearch"
                                    />
                                </div>
                            </div>
                        </template>
                        <template #default>

                            <div>
                                <b>
                                    {{ compTeamTeamTitle }}
                                </b>
                                <b class="float-end cursor-pointer">
                                 <base-card-drop-down>
                                     <template #title>
                                         <i class="bi bi-three-dots-vertical" ></i> Options
                                     </template>
                                     <template #default>
                                         <a class="dropdown-item"
                                             href="#"
                                             data-bs-toggle="modal"
                                             data-bs-target="#actionTeamModal"
                                             @click="loadCurrentTeamAction('create', {id:'newTeamAction'})"
                                          >
                                             <i class="bi bi-plus " ></i>
                                             Create new personel
                                         </a>
                                         <hr />
                                         <a class="dropdown-item" href="#" @click="teamStatus='active'">
                                             <i class="bi bs-icon-tiny" :class="teamStatus === 'active'?'bi-circle-fill':'bi-circle' " ></i>
                                             Active team members
                                         </a>
                                         <a class="dropdown-item" href="#" @click="teamStatus='suspended'">
                                             <i class="bi  "  :class="teamStatus === 'suspended'?'bi-circle-fill':'bi-circle'" ></i>
                                             Suspended team members
                                         </a>
                                         <a class="dropdown-item" href="#" @click="teamStatus='deleted'">
                                             <i class="bi " :class="teamStatus === 'deleted'?'bi-circle-fill':'bi-circle'" ></i>
                                             Deleted team members
                                         </a>
                                     </template>
                                 </base-card-drop-down>
                                 </b>
                            </div>

                            <base-card-response cardType="beige"
                                                cardColor="text-black"
                                                class="mt-1" v-for="team in compCurrentTeamList"
                                                :key="team.id">

                                <template #header>
                                    <span class="card-title" >
                                        {{ team.full_name }}
                                    </span>
                                    <span style="font-size:11px"
                                          :class="statusColor[team.status]">
                                        ( {{ team.status }} )
                                    </span>
                                    <span class="float-end">
                                        <base-button btnType="outline"
                                                     btnColor="secondary"
                                                     class="me-1"
                                                     data-bs-toggle="modal"
                                                     data-bs-target="#actionTeamModal"
                                                     @click="loadCurrentTeamAction('update', team )"
                                        >
                                            <i class="bi bi-pencil-square"></i> Update
                                        </base-button>
                                        <base-button  btnColor="secondary"
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#actionTeamModal"
                                                      @click="loadCurrentTeamAction('delete', team)"
                                        >
                                            <i class="bi bi-trash"></i> Delete
                                        </base-button>
                                    </span>
                                </template>

                                <template #default>
                                    <p class="cursor-pointer"
                                        data-bs-toggle="modal"
                                        data-bs-target="#actionTeamModal"
                                       @click="loadCurrentTeamAction('view', team)"
                                    >
                                        <u > View details </u>
                                    </p>
                                </template>

                            </base-card-response>

                        </template>
                    </base-card-plain>
                </div>
                <base-card-response cardType="secondary" class="mt-1" v-else>

                    <template #header> Your team members </template>

                    <template #default>
                        <p>
                            You currently don't have any team members.
                        </p>
                    </template>

                </base-card-response>
            </div>
        </div>
        <!-- Bordered table end -->
        <!-- full job description -->



    </div>
    <!-- END: Content-->

    <base-modal mId="actionTeamModal"  mBg="bg-bage" mSize="lg"  >
         <template #header-left-slot>
            {{ currentTeamActionTitle }}
         </template>

         <template #default>

             <!-- create admin teams  -->
             <create-admin-team
                 :currentTeamAction="currentTeamAction"
                 :currentTeamActionData="currentTeamActionData"
                 :existingRolesList="existingRolesList"
                 @emit-update-existing-team-list="updateExistingTeamList"
                 :key="currentTeamActionKey"
                 v-if="actionComponent"
             >
            </create-admin-team>
             <!--/ create admin teams -->

             <!-- create admin teams  -->
             <admin-personel-profile
                 :currentTeamActionData="currentTeamActionData"
                 :existingRolesList="existingRolesList"
                 :key="currentTeamActionKey"
                 v-if="viewComponent"
             >
            </admin-personel-profile>
             <!--/ create admin teams -->

         </template>
    </base-modal>


</template>

<script>
import { ref, toRefs,computed } from 'vue';
import CreateAdminTeam from '@/components/forms/admin/CreateAdminTeam.vue';
import AdminPersonelProfile from '@/components/admin/AdminPersonelProfile.vue';

export default {
    props: {
        existingRolesList :{
            type:Array,
            default:()=>[]
        },
        existingTeamList :{
            type:Array,
            default:()=>[]
        },
        isTeamRequest:{
            type:Boolean,
            default:true
        }
    },
    components:{
        CreateAdminTeam,
        AdminPersonelProfile
    },
    setup(props){

        const { existingTeamList: currentTeamList } = toRefs(props);

        const teamSearch = ref("");
        const teamStatus = ref("active");

        const compCurrentTeamList = computed(function(){
            let finalData = []

            finalData = currentTeamList.value.filter(function(item){
                    if(
                        (teamSearch.value?item.full_name.toLowerCase().replace(" ","_").search(teamSearch.value.toLowerCase().replace(" ","_")) >= 0 : true) &&
                        (item.status === teamStatus.value)
                      ){

                        return true;
                    }
                    return false
            })


            return finalData;
        })

        const compTeamTeamTitle = computed(function(){
            return teamSearch.value == ""?
                    `${compCurrentTeamList.value.length} ${teamStatus.value} personel exists in your  organization`:
                    `${compCurrentTeamList.value.length} "${teamSearch.value}" found`
        })

        function updateExistingTeamList(data, action){
            console.log(data)
            if(action === "create"){
                currentTeamList.value.push(data)
            }

            if(action === "update" || action === "delete"){

                let teamIndex = currentTeamList.value.map(object => object.client_id).indexOf(data.client_id);
                currentTeamList.value[teamIndex] = data
                // currentTeamList.value.sort((currentTeamList.value,b)=> (currentTeamList.value.name > b.name ? 1 : -1))

            }

            // if(action === "delete"){
            //     // let teamIndex = currentTeamList.value.map(object => object.id).indexOf(data);
            //     // currentTeamList.value.splice(teamIndex, 1)
            //     let teamIndex = currentTeamList.value.map(object => object.client_id).indexOf(data.client_id);
            //     currentTeamList.value[teamIndex] = data
            // }

        }

        const actionComponent = ref(false);
        const viewComponent = ref(false);

        const currentTeamAction = ref("");
        const currentTeamActionKey = ref(false);
        const currentTeamActionData = ref({});
        const currentTeamActionTitle = ref("");

        function loadCurrentTeamAction(action, data){
            actionComponent.value = action !== "view"?true:false;
            viewComponent.value = action === "view"?true:false;

            currentTeamAction.value = action;
            currentTeamActionKey.value = data.id;
            currentTeamActionData.value = data;
            currentTeamActionTitle.value = `${action.charAt(0).toUpperCase() + action.slice(1)} ${action == 'create'?' new team member':data.full_name}`;

        }

        const statusColor = {
            active:"text-success",
            suspend:"text-secondary",
            deleted:"text-danger"
        }


        return {
            compCurrentTeamList,
            compTeamTeamTitle,
            teamSearch,
            teamStatus,
            updateExistingTeamList,
            loadCurrentTeamAction,
            viewComponent,
            actionComponent,
            currentTeamAction,
            currentTeamActionData,
            currentTeamActionKey,
            currentTeamActionTitle,
            statusColor
        }
    }
}
</script>

<style scoped>
.multi-icon-spacer{
    padding:2px;
}
</style>
