<template>
    <!-- view profile details -->
    <div id="user-profile">
        <!-- profile header -->
        <div class="row">
            <div class="col-12">
                <div class="card profile-header mb-2">
                    <!-- profile cover photo -->
                    <img class="card-img-top" src="../../assets/images/profile/user-uploads/timeline.jpg" alt="User Profile Image" />
                    <!--/ profile cover photo -->

                    <div class="position-relative">
                        <!-- profile picture -->
                        <div class="profile-img-container d-flex align-items-center">
                            <div class="profile-img">
                                <img src="../../assets/images/portrait/small/avatar-s-2.jpg" class="rounded img-fluid" alt="Card image" />
                            </div>
                            <!-- profile title -->
                            <div class="profile-title ms-3">
                                <h2 class="text-white">{{ currentTeamActionData.full_name }}</h2>
                                <p class="text-white">{{ currentTeamActionData.email }}</p>
                            </div>
                        </div>
                    </div>

                    <!-- tabs pill -->
                    <div class="profile-header-nav">
                        <!-- navbar -->
                        <nav class="navbar navbar-expand-md navbar-light justify-content-end justify-content-md-between w-100">
                            <button class="btn btn-icon navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <i data-feather="align-justify" class="font-medium-5"></i>
                            </button>

                        </nav>
                        <!--/ navbar -->
                    </div>

                    <!-- tabs pill -->
                    <div class="profile-header-nav">
                        <!-- navbar -->
                        <nav class="navbar navbar-expand-md navbar-light justify-content-end justify-content-md-between w-100">
                            <button class="btn btn-icon navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <i data-feather="align-justify" class="font-medium-5"></i>
                            </button>


                        </nav>
                        <!--/ navbar -->
                    </div>
                </div>
            </div>
        </div>
        <!--/ profile header -->

        <!-- profile info section -->
        <section id="profile-info">
            <div class="row">

                <!-- center profile info section -->
                <div class="col-12 order-1 order-lg-2" >
                    <base-card-plain >
                        <template #header>
                            <div class="profile-user-info">
                                <h4 class="mb-1">Full Profile Details</h4>
                            </div>
                        </template>
                        <template #default>

                            <div class="row card-text mb-1">
                                <div class="col-2" >
                                    <b> Name: </b>
                                </div>
                                <div class="col-10" >
                                    {{ currentTeamActionData.full_name }}
                                </div>
                            </div>

                            <div class="row card-text my-1">
                                <div class="col-2" >
                                    <b> Email: </b>
                                </div>
                                <div class="col-10" >
                                    {{ currentTeamActionData.email }}
                                </div>
                            </div>

                            <div class="row card-text my-1">
                                <div class="col-2" >
                                    <b> Phone no: </b>
                                </div>
                                <div class="col-10" >
                                    {{ currentTeamActionData.phone_no }}
                                </div>
                            </div>

                            <div class="row card-text my-1">
                                <div class="col-2" >
                                    <b> Gender: </b>
                                </div>
                                <div class="col-10" >
                                    {{ currentTeamActionData.gender }}
                                </div>
                            </div>

                            <div class="row card-text my-1">
                                <div class="col-2" >
                                    <b> Country: </b>
                                </div>
                                <div class="col-10" >
                                    {{ currentTeamActionData.country }}
                                </div>
                            </div>

                            <div class="row card-text my-1">
                                <div class="col-2" >
                                    <b> Address: </b>
                                </div>
                                <div class="col-10" >
                                    {{ currentTeamActionData.address }}
                                </div>
                            </div>

                            <div class="row card-text my-1">
                                <div class="col-2" >
                                    <b> Role title: </b>
                                </div>
                                <div class="col-10" >
                                    {{ activeRoleData[0]['role_title'] }}
                                </div>
                            </div>

                            <div class="row card-text my-1">
                                <div class="col-2" >
                                    <b> Permissions: </b>
                                </div>
                                <div class="col-10" >
                                    <base-badge badgeColor="secondary" class="mb-1"
                                                v-for="(per,index) in JSON.parse(activeRoleData[0]['roles_permissions'])"
                                                :key="per+index">
                                            {{ per.replace("_"," ") }}
                                    </base-badge>
                                </div>
                            </div>

                            <div class="row card-text my-1">
                                <div class="col-2" >
                                    <b> Status: </b>
                                </div>
                                <div class="col-10" >
                                    <base-badge :badgeColor="statusColor[currentTeamActionData.status]">
                                        {{ currentTeamActionData.status }}
                                    </base-badge>
                                </div>
                            </div>

                        </template>

                    </base-card-plain>

                </div>
                <!--/ center profile info section -->




            </div>

        </section>
        <!--/ profile info section -->
    </div>
    <!-- view profile details -->
</template>

<script>
import { computed } from 'vue';


export default{
    props:{
        currentTeamActionData:{
            type:Object,
            default:()=>{}
        },
        existingRolesList :{
            type:Array,
            default:()=>[]
        }
    },
    setup( props ){

        const activeRoleData = computed(function(){
            let activeList =  props.existingRolesList.filter((item)=> props.currentTeamActionData.role_id === item.id)

            console.log(props.existingRolesList)
            console.log(props.currentTeamActionData)
            console.log(props.currentTeamActionData.role_id)
            console.log(activeList)

            return activeList
        })

        const statusColor = {
            active:"success",
            suspend:"secondary",
            deleted:"danger"
        }

        return {
            activeRoleData,
            statusColor
        }
    }

}
</script>

<style scoped>
.metric-bg{
    background-color:#f5f5f5;
    border-radius:5px;
    width:100%;
    padding:10px;
}
#user-profile {
  /*-------------profile header section---------*/
  /*-------- profile info section --------*/ }

#user-profile .profile-header {
  overflow: hidden; }

#user-profile .profile-header .profile-img-container {
  position: absolute;
  bottom: -2rem;
  left: 2.14rem;
  z-index: 2; }

#user-profile .profile-header .profile-img-container .profile-img {
  height: 8.92rem;
  width: 8.92rem;
  border: 0.357rem solid #fff;
  background-color: #fff;
  border-radius: 0.428rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1); }

#user-profile .profile-header .profile-header-nav .navbar {
  padding: 0.8rem 1rem; }

#user-profile .profile-header .profile-header-nav .navbar .navbar-toggler {
  line-height: 0; }

#user-profile .profile-header .profile-header-nav .navbar .profile-tabs .nav-item i,
#user-profile .profile-header .profile-header-nav .navbar .profile-tabs .nav-item svg {
  margin-right: 0; }

#user-profile #profile-info .profile-star {
  color: #babfc7; }

#user-profile #profile-info .profile-star i.profile-favorite,
#user-profile #profile-info .profile-star svg.profile-favorite {
  fill: #ff9f43;
  stroke: #ff9f43; }

#user-profile #profile-info .profile-likes {
  fill: #ea5455;
  stroke: #ea5455; }

#user-profile #profile-info .profile-polls-info .progress {
  height: 0.42rem; }

#user-profile .profile-latest-img {
  transition: all 0.2s ease-in-out; }

#user-profile .profile-latest-img:hover {
  transform: translateY(-4px) scale(1.2);
  z-index: 10; }

#user-profile .profile-latest-img img {
  margin-top: 1.28rem; }

#user-profile .block-element .spinner-border {
  border-width: 0.14rem; }

@media (max-width: 991.98px) {
  #user-profile .profile-latest-img img {
    width: 100%; } }

@media (min-width: 768px) {
  .profile-header-nav .profile-tabs {
    width: 100%;
    margin-left: 13.2rem; } }

@media (max-width: 575.98px) {
  #user-profile .profile-header .profile-img-container .profile-img {
    height: 100px;
    width: 100px; }
  #user-profile .profile-header .profile-img-container .profile-title h2 {
    font-size: 1.5rem; } }

</style>
