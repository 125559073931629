<template>
    <!-- form -->
    <form class="validate-form" @submit.prevent="createAdminTeam">
    <!-- filter questions -->
    <base-card-plain>
        <template class="mt-0" #header>
            <h4 class="card-title"></h4>
        </template>
        <template #default>



                <div class="row" >
                  <div v-if=" currentTeamAction === 'create' || currentTeamAction === 'update' " >
                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.name.msg?'text-danger':''" >
                                Fullname <span v-if="fv.name.msg" > | {{ fv.name.msg }}</span>
                            </label>
                            <div class="float-end cursor-pointer" @click="fv.name.active = !fv.name.active" v-if="currentTeamAction === 'update' ">
                                <i :class="compIsNameInputActive?'bi bi-lock':'bi bi-unlock' "></i> {{ compIsNameInputActive?'Unlock':'Lock' }}
                            </div>
                            <input v-model="fv.name.val"
                                   type="text" class="form-control"
                                   placeholder="Enter the name you'll like to give the role"
                                   @keyup="validate.validateFormData(fv,'name',true)"
                                   :disabled="compIsNameInputActive"
                             />
                        </div>
                    </div>

                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.email.msg?'text-danger':''" >
                                Email <span v-if="fv.email.msg" > | {{ fv.email.msg }}</span>
                            </label>
                            <div class="float-end cursor-pointer " @click="fv.email.active = !fv.email.active" v-if="currentTeamAction === 'update' ">
                                <i :class="compIsEmailInputActive?'bi bi-lock':'bi bi-unlock' "></i> {{ compIsEmailInputActive?'Unlock':'Lock' }}
                            </div>
                            <input v-model="fv.email.val"
                                   type="text" class="form-control"
                                   placeholder="Enter the name you'll like to give the role"
                                   @keyup="validate.validateFormData(fv,'email',true)"
                                   :disabled="compIsEmailInputActive"
                             />
                        </div>
                    </div>

                 <div class="col-12 col-lg-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.role.msg?'text-danger':''" for="basicSelect">
                                Role (optional) <span v-if="fv.role.msg" > | {{ fv.role.msg }}</span>
                            </label>
                            <select class="form-select" @change="validate.validateFormData(fv,'role',true)" v-model="fv.role.val" >
                                <option v-for="role in existingRolesList" :key="role.id" :value="role.id">
                                    {{ role.role_title }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-1" v-if="fv.role.val">
                            <p>
                                <u><b>Permissions associated with this role</b></u>
                            </p>
                            <p>
                                <base-badge badgeColor="secondary" class="mb-1"
                                            v-for="(per,index) in activeRoleList"
                                            :key="per+index">
                                        {{ per.replace("_"," ") }}
                                </base-badge>
                            </p>
                        </div>
                </div>

                <div v-if=" currentTeamAction === 'update' ">
                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.phoneNo.msg?'text-danger':''" >
                                Phone no (option) <span v-if="fv.phoneNo.msg" > | {{ fv.phoneNo.msg }}</span>
                            </label>
                            <input v-model="fv.phoneNo.val"
                                   type="text" class="form-control"
                                   placeholder="Enter team member phone number"
                                   @keyup="validate.validateFormData(fv,'phoneNo',true)"
                             />
                        </div>
                    </div>

                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.gender.msg?'text-danger':''" >
                                Gender (option) <span v-if="fv.gender.msg" > | {{ fv.gender.msg }}</span>
                            </label>
                            <select class="form-select" @change="validate.validateFormData(fv,'gender',true)" v-model="fv.gender.val" >
                                <option value=""> Select Gender </option>
                                <option v-for="(gender) in genderList" :key="gender" :value="gender">
                                    {{ gender }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.country.msg?'text-danger':''" >
                                Country (option) <span v-if="fv.country.msg" > | {{ fv.country.msg }}</span>
                            </label>
                            <select class="form-select" @change="validate.validateFormData(fv,'country',true)" v-model="fv.country.val" >
                                <option value="">Select Country</option>
                                <option v-for="country in countryList"
                                        :value="country.country_name"
                                        :key="country.id"
                                >
                                    {{country.country_alias}}
                                </option>
                            </select>
                        </div>
                    </div>


                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.address.msg?'text-danger':''" >
                                Address (option) <span v-if="fv.address.msg" > | {{ fv.address.msg }}</span>
                            </label>
                            <input v-model="fv.address.val"
                                   type="text" class="form-control"
                                   placeholder="Enter team address"
                                   @keyup="validate.validateFormData(fv,'address',true)"
                             />
                        </div>
                    </div>

                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.status.msg?'text-danger':''" >
                                Status <span v-if="fv.status.msg" > | {{ fv.status.msg }}</span>
                            </label>
                            <select class="form-select" @change="validate.validateFormData(fv,'status',true)" v-model="fv.status.val" >
                                <option value=""> Select Status </option>
                                <option v-for="(status) in statusList" :key="status" :value="status">
                                    {{ status }}
                                </option>
                            </select>
                        </div>
                    </div>


                  </div>

                 </div>
                </div>
                <div v-if=" currentTeamAction === 'delete' ">
                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.password.msg?'text-danger':''" >
                                Enter password to confirm delete
                                <span v-if="fv.password.msg" > | {{ fv.password.msg }}</span>
                            </label>
                            <input v-model="fv.password.val"
                                   type="password" class="form-control"
                                   placeholder="Enter password"
                                   @keyup="validate.validateFormData(fv,'password',true)"
                             />
                        </div>

                        <base-card-response cardType="secondary">

                            <template #header> <i class="bi bi-exclamation-circle"></i> Important warning. </template>
                            <template #default>
                                    If you delete this account. <b>{{ currentTeamActionData.full_name }}</b> will not be able to login again.

                            </template>

                        </base-card-response>
                    </div>
                </div>

                <div class="col-12" v-if="fv.reqFeedback.msg">
                    <base-list-tag :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                </div>

                <div class="col-12"  v-if="fv.showSubmit.val">
                    <base-button btnColor="primary" >
                         {{ currentTeamAction === "create"?"Create new team member":`${currentTeamAction} team member account` }}
                    </base-button>
                </div>
                <base-spinner  v-else></base-spinner>



        </template>
    </base-card-plain>
    <!-- / filter questions -->






    </form>
    <!--/ form -->
</template>

<script>
import { ref,reactive,computed,inject } from 'vue';
import validators from '@/hooks/validation';
export default{
    emits:['emitUpdateExistingTeamList'],
    props:{
        existingRolesList :{
            type:Array,
            default:()=>[]
        },
        currentTeamAction:{
            type:String,
            required:true,
            default:"empty"
        },
        currentTeamActionData:{
            type:Object,
            default:()=>{}
        }
    },
    setup( props, { emit } ){

        // set the validators function
        const validate = validators();

        // get the axios object
        const axios = inject('axios');  // inject axios

        //get the urls
        const coreUrl = inject('coreUrl');


        const activeRoleList = computed(function(){

            if(fv.role.val){

                let roleList = {}

                for(let i = 0;i < props.existingRolesList.length;i++ ){
                    roleList[`role_${props.existingRolesList[i]['id']}`] = JSON.parse(props.existingRolesList[i]['roles_permissions'])
                }

                let permissions = (fv.role.val && Object.keys(roleList).length > 0) ? roleList[`role_${fv.role.val}`]:[]


                return permissions;
            }

            return []


        })


        const compIsCreateUpdateRole = computed(function(){
            return props.currentTeamAction === "create" || props.currentTeamAction === "update" ? true : false;
        })


        const compActiveUpdate = computed(function(){
            return (props.currentTeamAction === "update" && Object.keys(props.currentTeamActionData).length > 0) ? true : false;
        })


        const fv = reactive({
            name : {status:false, val:compActiveUpdate.value?props.currentTeamActionData.full_name:"", active:true, msg:false, validate:["required",
                                                                     "safeSpaceInput",
                                                                     {option:"textMin",val:3},
                                                                     {option:"textMax",val:120}
                                                                    ]},
            email : {status:false, val:compActiveUpdate.value?props.currentTeamActionData.email:"", active:true, msg:false, validate:["required","email"]},
            role : {status:false, val:compActiveUpdate.value?props.currentTeamActionData.role_id:"", msg:false, validate:["required"]},
            phoneNo : {status:false, val:compActiveUpdate.value?props.currentTeamActionData.phone_no:"", msg:false, validate:["safeSpaceInput"]},
            gender : {status:false, val:compActiveUpdate.value?props.currentTeamActionData.gender:"", msg:false, validate:["safeSpaceInput"]},
            country : {status:false, val:compActiveUpdate.value?props.currentTeamActionData.country:"", msg:false, validate:["safeSpaceInput"]},
            address : {status:false, val:compActiveUpdate.value?props.currentTeamActionData.address:"", msg:false, validate:["safeSpaceInput"]},
            status : {status:false, val:compActiveUpdate.value?props.currentTeamActionData.status:"", msg:false, validate:["required"]},
            existingId: {status:false, val:compActiveUpdate.value?props.currentTeamActionData.client_id:"", msg:false},
            password : {status:false, val:"", msg:false, showPassword: false, validate:["required",
                                                                                          {option:"textMin",val:10},
                                                                                          {option:"textMax",val:64}
                                                                                         ]},
            reqFeedback : {status:false, val:"", msg:false},
            showSubmit : {status:false, val:true}
        });

        const compIsNameInputActive = computed(function(){
            return fv.name.active && (props.currentTeamAction === "update"?true:false);
        })

        const compIsEmailInputActive = computed(function(){
            return fv.email.active && (props.currentTeamAction === "update"?true:false);
        })

        const compIsCreateUpdateTeam = computed(function(){
            return props.currentTeamAction === "create" || props.currentTeamAction === "update" ? true : false;
        })

        const genderList = ref(['female','male','anonymous','other']);
        const statusList = ['active','suspended'];

        //get the data for country and cities
        const countryList = ref(false);

        // use axios to get the data
        axios.get(
            coreUrl.backendUrl+'get_country_data',
            {withCredentials: true}).then((response) =>{

            // return the data to result and update existing data variable
            countryList.value = response.data.data;

        }).catch((error)=>{
            console.log(error)
        })


        async function createAdminTeam(){

            const actionLinks = {
                create:'create_org_user_account',
                update:'update_org_user_account',
                delete:'delete_org_user_account'
            }


            fv.showSubmit.val = false;

            // load the form data , and append all the necessary fields
            let formData = new FormData();

            if(compIsCreateUpdateRole.value){
                formData.append("fv_name", fv.name.val);
                formData.append("fv_email", fv.email.val === props.currentTeamActionData.email ?props.currentTeamActionData.email:fv.email.val);
                formData.append("fv_role", fv.role.val);
                formData.append("fv_phoneNo", fv.phoneNo.val);
                formData.append("fv_gender", fv.gender.val);
                formData.append("fv_country", fv.country.val);
                formData.append("fv_address", fv.address.val);
            }

            if(props.currentTeamAction === "update"){
                formData.append("fv_status", fv.status.val);
                formData.append("fv_updateName", fv.name.val === props.currentTeamActionData.full_name ?"no":"yes");
                formData.append("fv_updateEmail", fv.email.val === props.currentTeamActionData.email ?"no":"yes");

            }


            if(props.currentTeamAction === "update" || props.currentTeamAction === "delete" ){
                formData.append("fv_orgUserId", props.currentTeamActionData.client_id?props.currentTeamActionData.client_id:"");
            }

            if( props.currentTeamAction === "delete" ){
                formData.append("fv_password", fv.password.val);
            }



            try{
                const response = await axios.post(
                    coreUrl.backendUrl+actionLinks[props.currentTeamAction],
                    formData,
                    {
                        withCredentials:true,
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }
                )

                const responseData = response.data;

                console.log(responseData)
                
                if(responseData.status){
                    fv.reqFeedback.status = true;
                    fv.reqFeedback.msg = responseData.message.req_msg;
                    fv.reqFeedback.val = "success";
                    fv.showSubmit.status = false;

                    // end the action of making a submission, and hide loader
                    fv.showSubmit.val = true;
                    console.log(responseData.data)

                    emit( 'emitUpdateExistingTeamList' , responseData.data, props.currentTeamAction )
                }else{
                    // load the error to the appropriate field
                    const fv_msg = responseData.message.fv_msg;
                    const req_msg = responseData.message.req_msg;

                    for(const key in fv_msg){
                        if(fv[key]){
                            fv[key]["status"] = false;
                            fv[key]["msg"] = fv_msg[key];
                        }
                    }


                    fv.reqFeedback.status = true;
                    fv.reqFeedback.msg = req_msg;
                    fv.reqFeedback.val = "danger";

                    // end the action of making a submission, and hide loader
                    fv.showSubmit.val = true;
                }
            }catch(_){

                const req_msg = "Your request cannot be processed at the moment. Try again Later.";

                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = req_msg;
                fv.reqFeedback.val = "danger";

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;
            }


        }


        return {
            fv,
            validate,
            createAdminTeam,
            compIsCreateUpdateTeam,
            activeRoleList,
            genderList,
            statusList,
            countryList,
            compIsNameInputActive,
            compIsEmailInputActive
        }
    }
}


</script>

<style scoped>
.role-type{
    background-color:#f5f5f5;
    border-radius:5px;
    width:100%;
    padding:10px;
}
</style>
